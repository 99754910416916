'use client';

import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Chip, Box, CardActions, Button, useTheme, useMediaQuery } from '@mui/material';
import ExternalLink from '@/components/ExternalLink';
import OptimizedImage from '@/components/OptimizedImage';

interface Project {
  id: number;
  title: string;
  description: string;
  imageUrl?: string;
  technologies?: string[];
  link?: string;
}

interface ProjectCardProps {
  project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = React.memo(({ project }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fallback for missing technologies array
  const renderTechnologies = useMemo(
    () =>
      (project.technologies ?? []).map((tech) => (
        <Chip key={tech} label={tech} size="small"  />
      )),
    [project.technologies]
  );

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ width: '100%', height: isMobile ? 200 : 250 }}>
        <OptimizedImage
          src={project.imageUrl}
          alt={project.title}
          width={1200}
          height={isMobile ? 675 : 844}
          priority={true}
        />
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="caption" component="h2" gutterBottom>
          {project.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" >
          {project.description}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: theme.spacing(0.5) }}>
          {renderTechnologies}
        </Box>
      </CardContent>
      <CardActions sx={{ mt: 'auto', justifyContent: 'flex-end', p: theme.spacing(2) }}>
        {project.link ? (
          <Button size="small" component={ExternalLink} href={project.link} variant="outlined">
            View Project
          </Button>
        ) : (
          <Box sx={{ width: '64px', height: '36px', visibility: 'hidden' }} />
        )}
      </CardActions>
    </Card>
  );
});

ProjectCard.displayName = 'ProjectCard';

export default ProjectCard;
