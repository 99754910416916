import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Chip/Chip.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/CircularProgress/CircularProgress.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ClientComponentLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/ContentLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/sections/about/components/TimelineList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/sections/contact/components/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sections/projects/components/ProjectCard.tsx");
