import React from 'react';
import Link from 'next/link';

interface ExternalLinkProps {
  href: string;
  children: React.ReactNode;
  [key: string]: any;
}

const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ href, children, ...props }, ref) => {
    return (
      <Link href={href} passHref legacyBehavior>
        <a target="_blank" rel="noopener noreferrer" ref={ref} {...props}>
          {children}
        </a>
      </Link>
    );
  }
);

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
