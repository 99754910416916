'use client';

import React, { createContext, useState, useEffect, useContext } from 'react';
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme, halloweenLightTheme, halloweenDarkTheme, bakeryLightTheme, bakeryDarkTheme } from './themes';

type ThemeMode = 'light' | 'dark';
type ThemeVariant = 'default' | 'halloween' | 'bakery';

interface ThemeContextType {
  mode: ThemeMode;
  variant: ThemeVariant;
  toggleTheme: () => void;
  cycleTheme: () => void;
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<ThemeMode>('dark');
  const [variant, setVariant] = useState<ThemeVariant>('bakery');

  useEffect(() => {
    const savedMode = localStorage.getItem('app-theme-mode') as ThemeMode | null;
    const savedVariant = localStorage.getItem('app-theme-variant') as ThemeVariant | null;
    if (savedMode) setMode(savedMode);
    if (savedVariant) setVariant(savedVariant);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.documentElement.setAttribute('data-theme', mode);
      document.documentElement.setAttribute('data-theme-variant', variant);
    }
  }, [mode, variant]);

  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('app-theme-mode', newMode);
  };

  const cycleTheme = () => {
    const newVariant = variant === 'default' ? 'halloween' : variant === 'halloween' ? 'bakery' : 'default';
    setVariant(newVariant);
    localStorage.setItem('app-theme-variant', newVariant);
  };

  const theme = mode === 'light'
    ? (variant === 'default' ? lightTheme : variant === 'halloween' ? halloweenLightTheme : bakeryLightTheme)
    : (variant === 'default' ? darkTheme : variant === 'halloween' ? halloweenDarkTheme : bakeryDarkTheme);

  return (
    <ThemeContext.Provider value={{ mode, variant, toggleTheme, cycleTheme, theme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};


