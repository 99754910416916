import { createTheme } from '@mui/material/styles';
import { designTokens } from './designTokens';

const baseTheme = {
  typography: {
    fontFamily: designTokens.fonts.fontFamily,
    h1: designTokens.typography.h1,
    h2: designTokens.typography.h2,
    h3: designTokens.typography.h3,
    h4: designTokens.typography.h4,
    body1: designTokens.typography.body1,
    body2: designTokens.typography.body2,
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  shape: {
    borderRadius: parseInt(designTokens.borderRadius.default),
  },
  breakpoints: {
    values: {
      xs: parseInt(designTokens.breakpoints.xs),
      sm: parseInt(designTokens.breakpoints.sm),
      md: parseInt(designTokens.breakpoints.md),
      lg: parseInt(designTokens.breakpoints.lg),
      xl: parseInt(designTokens.breakpoints.xl),
    },
  },
};

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: designTokens.colors.primaryLight,
    },
    secondary: {
      main: designTokens.colors.secondaryLight,
    },
    background: {
      default: designTokens.colors.backgroundLight,
      paper: designTokens.colors.surfaceLight,
    },
    text: {
      primary: designTokens.colors.textPrimaryLight,
      secondary: designTokens.colors.textSecondaryLight,
    },
  },
});
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: designTokens.colors.primaryDark,
    },
    secondary: {
      main: designTokens.colors.secondaryDark,
    },
    background: {
      default: designTokens.colors.backgroundDark,
      paper: designTokens.colors.surfaceDark,
    },
    text: {
      primary: designTokens.colors.textPrimaryDark,
      secondary: designTokens.colors.textSecondaryDark,
    },
  },
});

darkTheme.typography.h1 = {
  ...darkTheme.typography.h1,
  color: darkTheme.palette.primary.main,
};

export const bakeryLightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#C2410C',
      dark: '#9A3412',
      light: '#EA580C',
    },
    secondary: {
      main: '#A16207',
      dark: '#854D0E',
      light: '#CA8A04',
    },
    background: {
      default: '#dbdbdb',
      paper: '#FFFBEB',
    },
    text: {
      primary: '#27221D',
      secondary: '#44403C',
    },
  },
  typography: {
    ...baseTheme.typography,
    h1: {
      ...baseTheme.typography.h1,
      color: '#C2410C',
    },
  },
});

export const bakeryDarkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#D84315',
    },
    secondary: {
      main: '#BF360C',
    },
    background: {
      default: '#212121',
      paper: '#424242',
    },
    text: {
      primary: '#E0E0E0',
      secondary: '#BDBDBD',
    },
  },
  typography: {
    ...baseTheme.typography,
    h1: {
      ...baseTheme.typography.h1,
      color: '#D84315',
    },
  },
});

bakeryDarkTheme.typography.h1 = {
  ...bakeryDarkTheme.typography.h1,
  color: bakeryDarkTheme.palette.primary.main,
};

export const halloweenLightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#991B1B',
      dark: '#7F1D1D',
      light: '#B91C1C',
    },
    secondary: {
      main: '#92400E',
      dark: '#78350F',
      light: '#B45309',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FEF2F2',
    },
    text: {
      primary: '#292524',
      secondary: '#44403C',
    },
  },
});

halloweenLightTheme.typography.h1 = {
  ...halloweenLightTheme.typography.h1,
  color: halloweenLightTheme.palette.primary.main,
};

export const halloweenDarkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF6F00',
    },
    secondary: {
      main: '#D32F2F',
    },
    background: {
      default: '#3E2723',
      paper: '#4E342E',
    },
    text: {
      primary: '#FFCCBC',
      secondary: '#FFAB91',
    },
  },
});

halloweenDarkTheme.typography.h1 = {
  ...halloweenDarkTheme.typography.h1,
  color: halloweenDarkTheme.palette.primary.main,
};