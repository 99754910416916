'use client';
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@/theme/ThemeProvider';

interface TimelineItem {
  description: string;
  year: string;
  insight?: string;
}

interface TimelineListProps {
  items: TimelineItem[];
  title: string;
}

export const TimelineList: React.FC<TimelineListProps> = ({ items, title }) => {
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
        padding: theme.spacing(4)
      }}
    >
      <List sx={{ marginTop: theme.spacing(2) }}>
        {items.map((item, index) => (
          <Box key={index}
            sx={{
              borderRadius: 1.2,
              padding: theme.spacing(6, 4, 12),
              marginBottom: theme.spacing(4),
              backgroundColor: theme.palette.background.default,
            }}
          >
            <ListItem sx={{ marginBottom: theme.spacing(2) }}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="h2" sx={{ color: theme.palette.secondary.main }}>
                    {item.description}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="h3" sx={{ color: theme.palette.text.secondary }}>
                    {item.year}
                  </Typography>
                }
              />
            </ListItem>
            {item.insight && (
              <Typography variant="body2" component="p" sx={{ color: theme.palette.text.primary, marginBottom: theme.spacing(1) }}>
                {item.insight}
              </Typography>
            )}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default TimelineList;
