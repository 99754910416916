export const designTokens = {
  colors: {
    primaryLight: '#1E3A8A',
    primaryDark: '#0D47A1',
    secondaryLight: '#FF8A65',
    secondaryDark: '#FF7043',
    backgroundLight: '#F4F4F4',
    backgroundDark: '#121212',
    surfaceLight: '#FFFFFF',
    surfaceDark: '#1E1E1E',
    textPrimaryLight: '#1E1E1E',
    textSecondaryLight: '#5F6368',
    textPrimaryDark: '#E0E0E0',
    textSecondaryDark: '#B0BEC5',
    errorLight: '#D32F2F',
    errorDark: '#EF5350',
    warningLight: '#FFA000',
    warningDark: '#FFCA28',
    infoLight: '#1976D2',
    infoDark: '#64B5F6',
    successLight: '#388E3C',
    successDark: '#81C784',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '40px',
  },
  borderRadius: {
    default: '6px',
  },
  fonts: {
    base: '16px',
    fontFamily: '"Roboto", sans-serif',
    fontFamilyMono: '"Roboto Mono", monospace',
  },
  typography: {
    h1: {
      fontSize: 'clamp(2rem, 3vw, 4rem)',
      fontWeight: 700,
      lineHeight: 1.1,
    },
    h2: {
      fontSize: 'clamp(1.75rem, 2.5vw, 3rem)',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 'clamp(1.5rem, 2vw, 2.5rem)',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.35,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.55,
    },
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
};
