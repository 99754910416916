'use client';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

interface ContentLayoutProps {
  id: string;
  title: string;
  description?: string;
  children: React.ReactNode;
  isOdd?: boolean;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({ id, title, description, children, isOdd }) => {
  const { theme } = useTheme();

  const getTextAlign = () => {
    if (isOdd === undefined) return 'center';
    return isOdd ? 'right' : 'left';
  };

  return (
    <>
      <Box
        id={id}
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          flexGrow: 1,
          maxWidth: '800px',
          padding: theme.spacing(6, 4),
          margin: '0 auto',
          scrollMarginTop: 'var(--header-height)',
          textAlign: getTextAlign(),
          backgroundColor: theme.palette.background.paper + '80',
          backdropFilter: 'blur(10px)',
          boxShadow: `${theme.shadows[3]}, 0 4px 6px rgba(0, 0, 0, 0.1)`,
        }}
        borderBottom={3}
        borderColor={`rgba(${parseInt(theme.palette.primary.main.slice(1, 3), 16)}, ${parseInt(theme.palette.primary.main.slice(3, 5), 16)}, ${parseInt(theme.palette.primary.main.slice(5, 7), 16)}, 0.2)`}
        borderRadius={0.8}
      >
        {title &&
          <Typography variant="h1" component="h2" gutterBottom>
            {title}
          </Typography>
        }
        {description && (
          <Typography variant="body1" component="p">
            {description}
          </Typography>
        )}
        {children &&
          children}
      </Box>
      <br />
    </>
  );
};

export default ContentLayout;


