"use client";

import React, { Suspense, lazy, ComponentType } from "react";
import { CircularProgress, Box } from "@mui/material";

interface ClientComponentLoaderProps {
  Component: React.LazyExoticComponent<ComponentType<any>>;
}

const ClientComponentLoader: React.FC<ClientComponentLoaderProps> = ({
  Component,
}) => {
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      }
    >
      <Component />
    </Suspense>
  );
};

export default ClientComponentLoader;
