import React from 'react';
import Image from 'next/image';
import { tryCatch } from '@/utils/tryCatch';
import { useTheme } from '@/hooks/useTheme';
import { Box, Skeleton } from '@mui/material';
import { getPlaceholderSrc } from '@/utils/getPlaceholderSrc';

interface OptimizedImageProps {
    src?: string;
    alt?: string;
    width: number;
    height: number;
    priority?: boolean;
    className?: string;
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({
    src,
    alt = 'No Content Available',
    width,
    height,
    priority = false,
    className,
}) => {
    const { theme } = useTheme();
    const [imageSrc, setImageSrc] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const placeholderSrc = getPlaceholderSrc(width, height, theme, alt);
        setIsLoading(true);

        if (!src) {
            setImageSrc(placeholderSrc);
            setIsLoading(false);
        } else {
            tryCatch(
                async () => {
                    const response = await fetch(src, { method: 'HEAD' });
                    if (!response.ok) throw new Error('Image fetch failed');
                    return src;
                },
                (error) => {
                    console.error('Error loading image:', error);
                    return placeholderSrc;
                }
            ).subscribe((result: string | undefined) => {
                setImageSrc(result || placeholderSrc);
                setIsLoading(false);
            });
        }
    }, [src, alt, width, height, theme]);

    return (
        <Box
            className={className}
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            {isLoading && (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    animation="wave"
                />
            )}
            {imageSrc && (
                <Image
                    src={imageSrc}
                    alt={alt}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    quality={90}
                    priority={priority}
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                    onLoad={() => setIsLoading(false)}
                />
            )}
        </Box>
    );
};

export default OptimizedImage;
