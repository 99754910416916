export const getPlaceholderSrc = (
    width: number,
    height: number,
    theme: any,
    text: string = 'No Content Available'
): string => {
    const placeholderBgColor = theme.palette.background.default.replace('#', '');
    const placeholderTextColor = theme.palette.text.primary.replace('#', '');

    // Split text into words
    const words = text.split(' ');

    // Determine max characters per line based on image width
    const maxCharsPerLine = Math.floor(width / 10); // Adjust this ratio as needed

    // Build lines of text
    let lines: string[] = [];
    let currentLine = '';

    for (const word of words) {
        if ((currentLine + ' ' + word).length <= maxCharsPerLine) {
            currentLine += (currentLine ? ' ' : '') + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    if (currentLine) {
        lines.push(currentLine);
    }

    // Join lines with '\n' for line breaks
    const formattedText = lines.join('\\n');

    // Encode the text for URL
    const encodedText = encodeURIComponent(formattedText);

    // Determine if retina is needed (you might want to pass this as a prop)
    const retina = window.devicePixelRatio > 1 ? '@2x' : '';

    return `https://placehold.co/${width}x${height}${retina}/${placeholderBgColor}/${placeholderTextColor}.png?text=${encodedText}&font=roboto`;
};